import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  AliasesResourceService,
  AliasesSearchParams,
  AnalysisCode,
  AnalysisCodeRequest,
  AnalysisGroupResource,
  createSortParams,
  Order,
  orNull,
  PageRequest,
  PageResponse,
  SlideinService,
  SortDirection,
  SortParams,
  Transaction,
  TransactionBaseResource,
  TransactionResourceService,
} from '@demica/core/core';

import { ModalService } from '../../../../service/modal.service';

import { AliasesManagementSearchComponent } from '../aliases-management-search/aliases-management-search.component';

import { DataSource } from '../../../data-table/data-source.interface';
import { AliasesManagementSlideinContainerComponent } from '../aliases-management-slidein/aliases-management-slidein.container';

@Component({
  selector: 'trf-aliases-management',
  templateUrl: 'aliases-management.component.html',
  styleUrls: ['aliases-management.component.sass'],
})
export class AliasesManagementComponent implements OnInit, OnDestroy {
  @ViewChild('aliasesSearch')
  aliasesSearch: AliasesManagementSearchComponent;

  @Input()
  previewMode: boolean;
  @Input()
  transaction?: Transaction;
  @Input()
  versionPreviewMode?: boolean;

  isEmpty: boolean;

  analysisGroups: AnalysisGroupResource[] = [];
  transactionsList: TransactionBaseResource[] = [];
  dataSource: DataSource<AnalysisCode[]> = {
    data: new BehaviorSubject<AnalysisCode[]>([]),
  };

  modalMessage = 'MODAL_LOADER.LOADING_RESULTS';
  loading = true;
  dataLoaded = false;
  modalLoader = false;

  pageResponse: PageResponse;

  private _pageRequest: PageRequest = {
    pageNumber: 0,
    size: 25,
    sort: [createSortParams({ sortColumn: 'value', sortDirection: SortDirection.DESC })],
  };

  private _analysisCodeRequest: AnalysisCodeRequest;

  private _destroy$ = new Subject<void>();

  constructor(
    private _aliasesResourceService: AliasesResourceService,
    private _transactionResourceService: TransactionResourceService,
    private _slideinService: SlideinService,
    private _modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this._transactionResourceService
      .getTransactionsResource()
      .pipe(
        switchMap((transactions) => {
          this.transactionsList = transactions;
          return this._transactionResourceService.getAnalysisGroupsForTransactions(
            this.transaction ? [this.transaction.entityId] : transactions.map((t) => t.entityId),
          );
        }),
      )
      .subscribe((analysisGroups) => {
        const defaultAnalysisGroups = analysisGroups
          .filter((value) => getAnalysisGroupsToTranslate(value))
          .map((value) => ({
            name: value.name,
            entityId: value.entityId,
            key: value.entityId as string,
            transactionId: value.transactionId,
          }));
        const customNameAnalysisGroups = analysisGroups.filter(
          (value) => !getAnalysisGroupsToTranslate(value),
        );

        this.analysisGroups = [...defaultAnalysisGroups, ...customNameAnalysisGroups];
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onSearch(searchPayload: AliasesSearchParams): void {
    this._analysisCodeRequest = {
      analysisGroupName: orNull(() => searchPayload.analysisGroup.name),
      value: orNull(() => searchPayload.analysisCode),
      transactionIds: this.transaction
        ? [this.transaction.entityId]
        : orNull(() => searchPayload.transactionIds),
    };

    this._resolvePage(0, this._analysisCodeRequest);
  }

  onClear(): void {
    this.dataSource.data.next([]);
    this.dataLoaded = false;
  }

  onEdit(aliasData: AnalysisCode, previewMode: boolean): void {
    const slideInRef = this._slideinService.openSlideIn(AliasesManagementSlideinContainerComponent);
    slideInRef.onSuccess = () => this._resolvePage(0, this._analysisCodeRequest);
    slideInRef.instance.transaction = this.transaction;
    slideInRef.instance.transactionList = this.transactionsList;
    slideInRef.instance.analysisCodeData = aliasData;
    slideInRef.instance.previewMode = previewMode;
  }

  onAdd(): void {
    const slideInRef = this._slideinService.openSlideIn(AliasesManagementSlideinContainerComponent);
    slideInRef.onSuccess = () => this.aliasesSearch.onAddSuccess();
    slideInRef.instance.transaction = this.transaction;
    slideInRef.instance.transactionList = this.transactionsList;
  }

  onDelete(aliasData: AnalysisCode): void {
    this._modalService
      .openConfirmationModal(
        'ALIASES_MANAGEMENT.DELETION_MESSAGE',
        'ALIASES_MANAGEMENT.DELETION_ACTION',
        this._aliasesResourceService.deleteAlias(aliasData),
      )
      .onSuccess(() => this._resolvePage(0, this._analysisCodeRequest))
      .onError(this._resolvePage);
  }

  onPageChange(pageNumber: number): void {
    this._resolvePage(pageNumber, this._analysisCodeRequest);
  }

  isAdministrationView(): boolean {
    return !this.loading && !this.transaction;
  }

  onSortChange(params: SortParams): void {
    this._pageRequest.sort = [
      createSortParams({
        sortColumn: params.property,
        sortDirection: params.order === Order.ASC ? SortDirection.ASC : SortDirection.DESC,
      }),
    ];
    this._resolvePage(0, this._analysisCodeRequest);
  }

  private _resolvePage(pageNumber: number, analysisCodeRequest: AnalysisCodeRequest): void {
    this.modalLoader = true;
    this._pageRequest.pageNumber = pageNumber;
    this._aliasesResourceService
      .getAnalysisCodes(this._pageRequest, analysisCodeRequest)
      .subscribe((result) => {
        this.dataSource.data.next(result.data);
        this.pageResponse = result.meta.pagination;
        this.isEmpty = !!result.data.length;
        this.modalLoader = false;
        this.dataLoaded = true;
      });
  }
}

function getAnalysisGroupsToTranslate(analysisGroup: AnalysisGroupResource): boolean {
  const columnIdsToTranslate = [2, 17];
  return columnIdsToTranslate.includes(Number(analysisGroup.entityId));
}
