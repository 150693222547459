import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup, FormGroupDirective, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { getFormControlName } from '@demica/core/core';

import { FormRowClass } from '../../forms/model/form-row.class';
import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { MAX_TEXT_FIELD_LENGTH } from '../../forms/validators';

@Component({
  selector: 'trf-form-text-row',
  templateUrl: 'form-text-row.component.html',
  styleUrls: ['./form-text-row.component.sass'],
  providers: [{ provide: FormRowClass, useExisting: FormTextRowComponent }],
})
export class FormTextRowComponent implements FormRowClass, OnInit {
  @Input()
  name?: string;
  @Input()
  control?: FormControl;
  @Input()
  label?: string;
  @Input()
  placeholder?: string;
  @Input()
  labelClasses?: string;
  @Input()
  validations?: ValidationMessage[];
  @Input()
  inputClasses?: string[];
  @Input()
  readonly?: boolean;
  @Input()
  regExp?: RegExp;
  @Input()
  helperToolTip?: string | null;
  @Input()
  infoTooltip?: string;
  @Input()
  disable? = false;
  @Input()
  maxLength? = MAX_TEXT_FIELD_LENGTH;
  @Input()
  allowEmptyLabel = false;

  @ViewChild('textInput', { static: true })
  input: ElementRef;

  @ViewChild('additionalValidationContainer', { read: ViewContainerRef })
  additionalValidationContainer: ViewContainerRef;

  @Output()
  inputChanges = new EventEmitter<Observable<unknown>>();

  group: UntypedFormGroup;
  formControlName: string;
  protected readonly FormRowClass = FormRowClass;

  constructor(protected fgd: FormGroupDirective) {}

  ngOnInit() {
    this.group = this.fgd.form;
    this.formControlName = this.name ?? getFormControlName(this.control);
    const input = this.group.get(this.formControlName);

    if (this.regExp) {
      input.valueChanges.subscribe((data: string) => {
        if (data !== '' && !this.regExp.test(data)) {
          input.setValue(data.substring(0, data.length - 1));
        }
      });
    }

    this.inputChanges.emit(input.valueChanges);
  }
}
