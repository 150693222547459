import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { isE2E } from '@demica/utils';

import { ChartAPI, ChartConfiguration, generate } from 'c3';

@Injectable({
  providedIn: 'root',
})
export class ChartGenerateService {
  private renderer: Renderer2;

  constructor(private rendererFactory2: RendererFactory2) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
  }

  generate(config: ChartConfiguration): ChartAPI {
    if (isE2E()) {
      this.storeConfig(config.bindto, config);
    }

    return generate(config);
  }

  private storeConfig(
    // eslint-disable-next-line
    bindTo: string | HTMLElement | d3.Selection<any, unknown, any, unknown>,
    config: ChartConfiguration,
  ): void {
    if (typeof bindTo === 'string' || bindTo instanceof HTMLElement) {
      const element = typeof bindTo === 'string' ? this.getElementBySelector(bindTo) : bindTo;

      element.dataset.e2eChartConfig = JSON.stringify(config);
    }
  }

  private getElementBySelector(selector: string): HTMLElement {
    return this.renderer.selectRootElement(selector);
  }
}
