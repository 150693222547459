import { EntityId } from '@demica/core/core';

import { CodeWithEntityReference } from '../debtor-commons';

export enum StatusType {
  Reporting,
  Processing,
  Upload,
  Conversion,
  Other,
}

export interface StatusFile {
  stepId: string;
  approvalType: ApprovalType;
  approvalAllowed: boolean;
  approved: boolean;
  finished: boolean;
  rollbackAllowed: boolean;
  clientName: string;
  clientId: EntityId;
  transactionId: EntityId;
  transactionName: string;
  environmentName: string;
  environmentId: EntityId;
  processingDate: string;
  stepStatus: StepStatusDetails;
  stepNumber: number;
  reportingStatus: ReportingStatusDetails;
  entityId: EntityId;
  filesDetails: StatusFileRow[];
  scheduledForRollback: boolean;
  transactionProcessingTriggerId: number;
}

export interface StatusFileRow {
  entityId: EntityId;
  uploaderName: string;
  behalfOfName: string;
  fileName: string;
  originalFileName: string;
  errorFileName: string;
  convertedFileName: string | null;
  conversionStatus: ConversionStatusDetails;
  uploadStatus: UploadStatusDetails;
  processingStatus: ProcessingStatusDetails;
  fileReadyForDownload: boolean;
  sellerNames: string[];
  skipped: boolean;
}

interface StatusDetails<StatusType, FailureType> {
  statusValue: StatusType;
  failureReason: FailureType;
}

export type ConversionStatusDetails = StatusDetails<FileConversionStatus, ConversionFailureReason>;
export type UploadStatusDetails = StatusDetails<FileUploadStatus, UploadFailureReason>;
export type ProcessingStatusDetails = StatusDetails<FileProcessingStatus, ProcessingFailureReason>;
export type StepStatusDetails = StatusDetails<FileStepStatus, StepStatusFailureReason>;

export interface ReportingStatusDetails {
  statusValue: FileReportingStatus;
  inProgressReason: ReportingInProgressReason;
}

export interface FilterStatus {
  clients: CodeWithEntityReference[];
  opcos: CodeWithEntityReference[];
  transactions: CodeWithEntityReference[];
}

export abstract class ViewFileStatus {
  abstract getFileStatusTitle(): string;
}

export type BaseFileStatus = 'IN_PROGRESS' | 'WARNING' | 'FAILED' | 'SUCCESS';

export type FileConversionStatus = BaseFileStatus | 'NONE';

export type FileUploadStatus = BaseFileStatus | 'NONE';

export type FileProcessingStatus = BaseFileStatus | 'NONE';

export type FileReportingStatus = BaseFileStatus | 'NONE';

export type FileStepStatus = BaseFileStatus;

export type ApprovalType = 'NONE' | 'AUTOMATIC' | 'MANUAL' | 'SEMI_AUTOMATIC';

export enum ConversionFailureReason {
  INCORRECT_FORMAT = 'INCORRECT_FORMAT',
  MISSING_CACHED_VALUE = 'MISSING_CACHED_VALUE',
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
}

export enum UploadFailureReason {
  MISSING_FILE = 'MISSING_FILE',
  MISSING_COLUMN = 'MISSING_COLUMN',
  INVALID_ITEMS = 'INVALID_ITEMS',
  NONE = 'NONE',
}

export enum ProcessingFailureReason {
  MISSING_PARTY = 'MISSING_PARTY',
  OTHER = 'OTHER',
  NONE = 'NONE',
}

export enum ReportingInProgressReason {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  WAITING_FOR_EXTERNAL_UPLOAD = 'WAITING_FOR_EXTERNAL_UPLOAD',
  NONE = 'NONE',
}

export enum StepStatusFailureReason {
  NONE = 'NONE',
  MISSING_TIME_SERIES = 'MISSING_TIME_SERIES',
  MISSING_FX_RATES = 'MISSING_FX_RATES',
  PROCESSING_WORKING_DAYS = 'PROCESSING_WORKING_DAYS',
  MISSING_PARTY = 'MISSING_PARTY',
}
