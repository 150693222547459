import { AnalyticsServerSideClient } from './server-side/analytics-server-side-client.service';
import { AnalyticsTealiumClient } from './tealium/analytics-tealium-client.service';

// type: Record<AnalyticsClientType, AnalyticsClient based>
// Cannot type by inheritance with different constructors: https://stackoverflow.com/q/33442687

export const ANALYTICS_CLIENT_PROVIDERS = {
  server_side: AnalyticsServerSideClient,
  tealium: AnalyticsTealiumClient,
};
