import { AppInitializerAfterConfig } from '../token/app-initializer-after-config.token';
import { RemoteConfigProvider } from '../token/remote-config-provider.token';

export function appConfigInitializerFactory(
  remoteConfigProviders: RemoteConfigProvider[],
  afterConfigInitializers: AppInitializerAfterConfig[],
) {
  return async () => {
    await Promise.all(remoteConfigProviders.map((provider) => provider.configAsPromise));

    for (const initializer of afterConfigInitializers) {
      await initializer();
    }
  };
}
