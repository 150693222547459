export const TLF_FEES = 'TLF_FEES';
export const SELLER_BANK_ACCOUNTS = 'SELLER_BANK_ACCOUNTS';
export const ASSET_BASE_SELLER_BANK_ACCOUNTS = 'ASSET_BASE_SELLER_BANK_ACCOUNTS';
export const PROGRAMME_MODELLING_DDE = 'PROGRAMME_MODELLING_DDE';
export const FUNDING_ALERTS = 'FUNDING_ALERTS';
export const TRANSACTION_VAT = 'TRANSACTION_VAT';

export const CHART_FUNDING_PERFORMANCE_SRF = 'CHART_FUNDING_PERFORMANCE_SRF';
export const CHART_FUNDING_INELIGIBLE_SRF = 'CHART_FUNDING_INELIGIBLE_SRF';
export const CHART_FUNDING_RESERVE_SRF = 'CHART_FUNDING_RESERVE_SRF';
export const CHART_FUNDING_FUNDING_SRF = 'CHART_FUNDING_FUNDING_SRF';
export const CHART_FUNDING_FEES_AND_CHARGES_SRF = 'CHART_FUNDING_FEES_AND_CHARGES_SRF';

export const CHART_FUNDING_PERFORMANCE_TLF = 'CHART_FUNDING_PERFORMANCE_TLF';
export const CHART_FUNDING_INELIGIBLE_TLF = 'CHART_FUNDING_INELIGIBLE_TLF';
export const CHART_FUNDING_FEES_AND_CHARGES_TLF = 'CHART_FUNDING_FEES_AND_CHARGES_TLF';
export const DASHBOARD_FUNDING_RECOURSE_ACCOUNT_RUNNING_BALANCES_TLF =
  'DASHBOARD_FUNDING_RECOURSE_ACCOUNT_RUNNING_BALANCES_TLF';

export const ORGANIZATION_UNITS = 'ORGANIZATION_UNITS';

export const TRANSACTION_PAUSE_UNPAUSE = 'TRANSACTION_PAUSE_UNPAUSE';

export const REGION_REPORTS = 'REGION_REPORTS';

export const REGION_MANAGEMENT_EMAILS = 'REGION_MANAGEMENT_EMAILS';

export const TRANSACTION_INSTANCES_LIST_FEATURE_TOGGLE_GROUP = ['TRANSACTION_INSTANCES_LIST'];
