<div
  class="missing-aliases-tab-content"
  testid="missing-aliases-tab-content"
>
  <ngp-inline-loader [loading]="loadingFilters"></ngp-inline-loader>

  <ng-container *ngIf="!loadingFilters">
    <trf-alias-missing-search
      #aliasesSearch
      [transactions]="transaction ? null : transactions"
      [dataSources]="dataSources"
      [analysisGroups]="analysisGroups"
      [environments]="environments"
      [environmentsMapping]="environmentsMapping"
      (searchChange)="onSearch($event)"
      (filterChange)="onFilterChange()"
      (clearChange)="onClear()"
    ></trf-alias-missing-search>
  </ng-container>

  <trf-modal-loader
    message="MODAL_LOADER.LOADING_RESULTS"
    [loadingData]="loading"
  ></trf-modal-loader>

  <div class="trf-section-content">
    <div
      *ngIf="showButtons"
      class="buttons"
    >
      <ngp-button
        icon="plus"
        key="ALIASES.BUTTON_ADD"
        [classes]="enableButtons ? 'tertiary' : 'inactive'"
        (click)="onAddMappings()"
        testid="add-button"
      ></ngp-button>

      <ngp-button
        icon="ban"
        key="ALIASES.BUTTON_REJECT"
        [classes]="enableButtons ? 'tertiary' : 'inactive'"
        (click)="onRemoveMappings()"
        testid="reject-button"
      ></ngp-button>

      <ngp-button
        icon="plus"
        key="ALIASES.BUTTON_ADD_ALL"
        [classes]="enableAllButtons ? 'tertiary' : 'inactive'"
        (click)="onAddAllMappings()"
        testid="add-all-button"
      ></ngp-button>

      <ngp-button
        icon="ban"
        key="ALIASES.BUTTON_REJECT_ALL"
        [classes]="enableAllButtons ? 'tertiary' : 'inactive'"
        (click)="onRejectAllMappings()"
        testid="reject-all-button"
      ></ngp-button>
    </div>

    <trf-modal-loader
      message="MODAL_LOADER.LOADING_RESULTS"
      [loadingData]="loading"
    ></trf-modal-loader>

    <ng-container *ngIf="dataLoaded && !loading">
      <div *ngIf="!isEmpty">
        <div class="trf-section-header">
          <h3>
            {{ "ALIASES.TITLE_MISSING_LIST" | translate }}
          </h3>
        </div>

        <trf-data-table
          tableClasses="disabled-rows"
          [dataSource]="dataSource"
          [headerConfiguration]="headerConfig"
          [rowConfiguration]="rowConfig"
          [allSelected]="allSelected"
          [indeterminateSelected]="indeterminateSelected"
          [tableLabel]="'ALIASES.TITLE_MISSING_LIST'"
          testid="missing-aliases-table"
        ></trf-data-table>
        <trf-spring-page-number
          *ngIf="showPages"
          [pageResponse]="pageResponse"
          (pageChange)="onPageChange($event)"
        ></trf-spring-page-number>
      </div>

      <div class="trf-section">
        <ngp-empty
          key="EMPTY_VIEW.NO_RESULTS"
          height="12vh"
          [isVisible]="isEmpty"
        ></ngp-empty>
      </div>
    </ng-container>
  </div>
</div>
