export enum AnalyticsClientType {
  // client side enums
  TEALIUM = 'tealium',

  /*
  implicit client type representing NGP-owned backend service
  - will be enabled if serverSide is enabled in configmap
  - source: deployment/docker/configure-app-config-file.ts
   */
  SERVER_SIDE = 'server_side',
}

export interface AnalyticsConfig {
  bufferSize: number;
  bufferTimeout: number;
  activeConfigurations?: AnalyticsClientType[];
}
