import { Component, Input, OnInit } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, Validators } from '@angular/forms';

import { take, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
  SubjectValidationItem,
  SubjectValidationSeverity,
  SubjectValidityService,
} from '@demica/core/core';

import { validateNotEmpty } from '../../forms/validators';
import {
  maxDescriptionLength,
  versionDescriptionValidations,
  versionNameValidations,
} from './new-version-validations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const SPECIAL_CASE_REASON = 'PROGRAMME_REASSESS_POOL_FILTER_WITH_PRICING';
const SPECIAL_CASE_METADATA = 'context';
const TRANSLATED_METADATA_SUFFIX = 'Translated';

@Component({
  selector: 'trf-new-version-modal',
  templateUrl: 'new-version-modal.component.html',
  styleUrls: ['./new-version-modal.component.sass'],
})
export class NewVersionModalComponent implements OnInit {
  @Input()
  createAction: (data: unknown) => Promise<void>;
  @Input()
  nameValidator: AsyncValidatorFn;
  @Input()
  subjectTypeId: number;
  @Input()
  subjectId: number;
  @Input()
  subjectEntityRevision: number;

  form = this.fb.group({
    name: ['', [validateNotEmpty]],
    description: ['', [Validators.maxLength(maxDescriptionLength)]],
  });

  submitted = false;
  subjectInvalid = false;
  subjectIncomplete = false;
  specialCase = false;
  validationWarnings: SubjectValidationItem[];
  validationInfos: SubjectValidationItem[];
  specialCaseWarnings: SubjectValidationItem[];

  versionNameValidations = versionNameValidations(this.form, () => this.submitted);
  versionDescriptionValidations = versionDescriptionValidations(this.form, () => this.submitted);
  private requestInProgress: boolean;

  constructor(
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    private translate: TranslateService,
    private subjectValidityService: SubjectValidityService,
  ) {}

  ngOnInit(): void {
    this.form.controls.name.setAsyncValidators(this.nameValidator);

    queueMicrotask(() => {
      this.form.controls.name.setValue(
        this.translate.instant('NEW_VERSION_MODAL.DEFAULT_VERSION_NAME', {
          revision: this.subjectEntityRevision,
        }),
      );

      this.subjectValidityService
        .checkSubjectValidity(this.subjectTypeId, this.subjectId, this.subjectEntityRevision)
        .subscribe((response) => {
          this.validationWarnings = response.filter(
            (r) =>
              r.severity == SubjectValidationSeverity.ERROR &&
              r.invalidityReason != SPECIAL_CASE_REASON,
          );
          this.validationInfos = response.filter(
            (r) =>
              r.severity == SubjectValidationSeverity.INFO &&
              r.invalidityReason != SPECIAL_CASE_REASON,
          );
          this.specialCaseWarnings = response.filter(
            (r) => r.invalidityReason == SPECIAL_CASE_REASON,
          );
          this.subjectInvalid = this.validationWarnings.length > 0;
          this.subjectIncomplete = this.validationInfos.length > 0;
          this.specialCase = this.specialCaseWarnings.length > 0;
        });
    });
  }

  onSave(): void {
    this.submitted = true;
    if (this.requestInProgress) return;
    if (this.form.pending) {
      this.form.statusChanges
        .pipe(
          take(1),
          tap(() => this.onSave()),
        )
        .subscribe();
      return;
    }
    if (this.form.valid) {
      this.requestInProgress = true;
      const versionToCreate = {
        ...this.form.value,
        subjectEntityRevision: this.subjectEntityRevision,
      };
      this.createAction(versionToCreate)
        .then(() => this.modal.dismiss())
        .catch((e) => console.error(e));
    }
  }

  onClose(): void {
    this.modal.dismiss();
  }

  getValidationErrorTranslation(validationError: SubjectValidationItem): string {
    if (validationError.invalidityReason == SPECIAL_CASE_REASON) {
      if (validationError.metadata && SPECIAL_CASE_METADATA in validationError.metadata) {
        validationError.metadata[SPECIAL_CASE_METADATA + TRANSLATED_METADATA_SUFFIX] =
          this.translate.instant(
            'PRICING_SET.' + validationError.metadata[SPECIAL_CASE_METADATA] + '.TAB_TITLE',
          );
      }
    }

    return this.translate.instant(
      'ENUMERATIONS.VALIDATION_MESSAGES.' + validationError.invalidityReason,
      { invalidElement: validationError.metadata },
    );
  }
}
