import { Inject, Injectable, Optional } from '@angular/core';

import {
  DEFAULT_POLYFILL_IE_CSS_GLOBAL_VAR_NAME,
  getCssVariable,
  setCssVariable,
} from '@demica/ui-core-api';

import { POLYFILL_IE_CSS_GLOBAL_VAR_NAME_TOKEN } from '../tokens';

// duplicated implementation of this service exists in @demica/core/core (should be cleaned up to one implementation after we discuss overall application division to libraries and responsibilities)
@Injectable({
  providedIn: 'root',
})
export class CssVariableService {
  constructor(
    @Optional() @Inject(POLYFILL_IE_CSS_GLOBAL_VAR_NAME_TOKEN) private pollyFillVarName: string,
  ) {}

  get(variableName: string): string {
    const iePolyfillVarName = this.pollyFillVarName ?? DEFAULT_POLYFILL_IE_CSS_GLOBAL_VAR_NAME;
    return getCssVariable(variableName, iePolyfillVarName)?.replace(/"/g, '');
  }

  set(variable: string, value: string, important = false) {
    setCssVariable(variable, value, important);
  }

  variableExist(variableName: string) {
    return !!this.get(variableName);
  }
}
