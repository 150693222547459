import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { DictionaryEntry, EntityId, EntityReference } from '@demica/core/core';

import { DynamicFormGroup } from '../../../../forms/dynamic-form-group.directive';
import { requireSelect } from '../../../../forms/validators';
import { AliasFormValidations } from '../../../../validation/alias-form-validations';
import { AliasesValidator } from '../../../../validation/analysis-code-form-validations';

export type AliasesManagementCountrySelectorDynamicGroup = {
  countriesGroup?: FormGroup<{ countries: FormControl<EntityReference> }>;
};

@Component({
  selector: 'trf-aliases-management-country-selector-group',
  templateUrl: './aliases-management-country-selector-group.component.html',
  styleUrls: ['./aliases-management-country-selector-group.component.sass'],
})
export class AliasesManagementCountrySelectorGroupComponent extends DynamicFormGroup<
  AliasesManagementCountrySelectorDynamicGroup,
  'countriesGroup'
> {
  @Input()
  countries: DictionaryEntry[] = [];

  @Input()
  transactionId: EntityId;

  @Input()
  previewMode: boolean;

  constructor(fb: FormBuilder, private _aliasFormValidations: AliasFormValidations) {
    super(fb);
  }

  getFormGroup() {
    return {
      name: 'countriesGroup' as const,
      controls: [
        {
          name: 'countries' as const,
          defaultValue: null as EntityReference,
          validations: [requireSelect],
          asyncValidations: this.previewMode
            ? []
            : [this._aliasFormValidations.hasAvailableCountryCode(this.transactionId)],
          messages: AliasesValidator.code,
        },
      ],
    };
  }
}
