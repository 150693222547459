<trf-slidein-container
  headerText="ALIASES_MANAGEMENT.SLIDEIN.HEADER"
  (slideinClose)="onClose()"
  testid="analysis-code-slide-in"
>
  <ngp-inline-loader [loading]="!isInitialized()"></ngp-inline-loader>

  <div
    *ngIf="isInitialized()"
    class="trf-section"
    trfSlideinFormChanges
    [previewMode]="previewMode"
  >
    <div class="trf-section-content">
      <legend>{{ "LEGEND.REQUIRED_ASTERISK" | translate }}</legend>
    </div>

    <div class="trf-section-content">
      <form [formGroup]="form">
        <trf-form-select-row
          *ngIf="!transaction"
          defaultLabel="ALIASES_MANAGEMENT.FORM_TRANSACTION_DEFAULT_VALUE"
          label="ALIASES_MANAGEMENT.TRANSACTION"
          labelClasses="required"
          translationPrefix="COLUMN_DEFINITION_TYPES"
          [disabled]="editMode"
          [control]="form.controls.transaction"
          [options]="transactionList"
          [validations]="requireValidations"
          testid="transaction-name"
        ></trf-form-select-row>

        <trf-form-select-row
          defaultLabel="ALIASES_MANAGEMENT.FORM_ANALYSIS_GROUP_DEFAULT_VALUE"
          label="ALIASES_MANAGEMENT.ANALYSIS_GROUP"
          labelClasses="required"
          translationPrefix="COLUMN_DEFINITION_TYPES"
          [disabled]="controlsDisabled || disabledToModify"
          [control]="form.controls.analysisGroup"
          [options]="analysisGroups"
          [validations]="requireValidations"
          testid="aliases-group-name"
        ></trf-form-select-row>

        <trf-form-text-row
          *ngIf="showAnalysisCode"
          label="ALIASES_MANAGEMENT.ANALYSIS_CODE"
          labelClasses="required"
          placeholder="ALIASES_MANAGEMENT.ANALYSIS_CODE_PLACEHOLDER"
          [disable]="!showAnalysisCode || controlsDisabled || isAnalysisCodeFormActive()"
          [control]="form.controls.analysisCode"
          [validations]="codeValidation"
          [maxLength]="maxLength"
          [helperToolTip]="
            isActiveSellerCode ? 'ALIASES_MANAGEMENT.ANALYSIS_CODE_SELLER_CODE_TOOLTIP' : null
          "
          data-testid="analysis-code-name"
        ></trf-form-text-row>

        <trf-aliases-management-currency-selector-group
          [parentForm]="form"
          [isActive]="isActiveCurrency"
          [currencyCodes]="currencyCodes"
          [submitted]="submitted"
          [transactionId]="transactionId"
          [previewMode]="previewMode"
        ></trf-aliases-management-currency-selector-group>

        <trf-aliases-management-country-selector-group
          [parentForm]="form"
          [isActive]="isActiveCountry"
          [countries]="countries"
          [submitted]="submitted"
          [transactionId]="transactionId"
          [previewMode]="previewMode"
        ></trf-aliases-management-country-selector-group>
      </form>
    </div>

    <trf-list-alias-mappings
      [aliasMappings]="aliasMappings"
      [environments]="environments"
      [metaData]="metaData"
      [transactionDataSource]="transactionDataSource"
      [columnTypeId]="columnDefinitionId"
      [previewMode]="previewMode"
      (addChanged)="onAddNewAliasMapping($event)"
      (deleteChanged)="onDeleteAliasMapping($event)"
      (environmentChanged)="onEnvironmentChange($event)"
    ></trf-list-alias-mappings>

    <div class="trf-slidein-footer">
      <trf-button-controls
        negativeName="ITEM_STATUS.BUTTON_CLOSE"
        positiveName="ITEM_STATUS.BUTTON_SAVE"
        [disableSaveButton]="disabledSaveButton"
        [form]="form"
        [previewMode]="previewMode"
        (negativeEvent)="onClose()"
        (positiveEvent)="onSave()"
      ></trf-button-controls>
    </div>
  </div>
</trf-slidein-container>
