import { Injectable } from '@angular/core';

import { CSS_VARIABLES_PROP_NAME } from '../styling';

// duplicated implementation of this service exists in @demica/components (should be cleaned up to one implementation after we discuss overall application division to libraries and responsibilities)
@Injectable({
  providedIn: 'root',
})
export class CssVariableService {
  set(variable: string, value: string, important = false): void {
    this.getDocumentStyles().setProperty(variable, value, important ? 'important' : '');
  }

  get(variableName: string): string {
    return (
      getComputedStyle(document.documentElement).getPropertyValue(variableName).trim() ||
      (window[CSS_VARIABLES_PROP_NAME] && window[CSS_VARIABLES_PROP_NAME][variableName])
    );
  }

  variableExist(variableName: string): boolean {
    return !!this.get(variableName);
  }

  getDocumentStyles(): CSSStyleDeclaration {
    return document.documentElement.style;
  }
}
